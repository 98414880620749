import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false
import "./home-controllers"

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

require("./packs/scrolling");

